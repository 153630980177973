import { getTbliveAnchorInfo, setTbliveAnchorInfo, removeTbliveAnchorInfo } from "@/utils/localStorage";

export default {
    namespaced:true,
    state:{
        tbAnchorInfo: getTbliveAnchorInfo() || null,
    },
    mutations: {
        changeAnchorInfo(state,payload){
            state.tbAnchorInfo = payload;
            setTbliveAnchorInfo(state.tbAnchorInfo);
        },
        removeAnchorInfo(state,payload){
            state.tbAnchorInfo = null;
            removeTbliveAnchorInfo();
        }
    }
}