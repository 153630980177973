<template>
    <div class="home">
         <div v-if="!calculateSevenDay(userInfo.endTime)" class="mcn-text-error">
            <p>温馨提示：您的软件将于<b>{{ userInfo.endTime | calculateDaysDifference }}</b>天后到期，为了避免出现无法使用情况，请提前与我们联系续订。</p>
         </div>

        <div class="container-index">  
        <h1>主播管理</h1>
        <div class="announcement">  
          <h2>解放双手！提高效率！抢占市场！</h2>  
          <p><strong>功能介绍</strong></p>  
          <ul>  
            <li>支持批量添加主播账号。</li>  
            <li>支持开设子账号运营权限。</li>  
            <li>支持多个机构联合运营。</li>  
            <li>支持批量设置选品条件、自动批量上品。</li>  
            <li>支持pc和手机端智能提词、自动弹商品、手动自动过品切换等。</li>  
            <li>支持批量回放下载、录播。</li>  
            <li>支持手动自动筛选优质商品、高销商品复用等。</li>  
            <li>支持一键清理试用期的素人主播。</li>  
            <li>支持直播严选、天猫超市、源头优选、淘工厂等货源类目。</li>  
            <li><a href="https://www.yuque.com/koudai666/nsxi4d/ha6glc" target="_blank">使用帮助&功能介绍</a></li>  
          </ul>  
          <!-- <p class="gg-right">
              <a  class="btn-mcnget">试用主播</a>  
              <a  class="btn-mcnget">正式主播</a>  
              <a  class="btn-mcnget">失效主播</a>  

          </p> -->
        </div>  
        <!-- <h2>周边工具</h2>   -->
        <div class="tutorial">
          <p><strong>周边业务</strong></p>  
          <ul>  
            <li>适用于正式主播的电脑直播：<a href="https://www.yuque.com/koudai666/nsxi4d/dcy7ug" target="_blank">下载&安装教程</a></li>  
            <li>适用于试用主播挂播：<a href="https://obsproject.com/" target="_blank">obs推流软件</a><span>|</span><a href="https://www.yuque.com/koudai666/nsxi4d/fognvg4fiz2385qm" target="_blank">录播回放教程</a></li>  
             <li >另有阿里官方合作业务：主播涨关注粉丝、直播间真实流量等数据业务。</li>  
            <!-- <li>head 新增快捷入口：<a href="https://hot.taobao.com/">热浪联盟</a><span>|</span><a>Mcn机构后台</a></li>   -->
            <p class="gg-right hoverable">
              <button class="btn-mcnget">咨询客服 weikoudai<img src="https://zbm666.com/image/weixin.png" class="hidden-img"></button>
            </p>
          </ul>  
        </div>  
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HomeView',
  computed:{
    ...mapState({
      userInfo:state => state.userInfo.userInfo
    })
  },
  created(){
    
  },
  methods:{
    calculateSevenDay(date){
      return this.$calculateDays(date) > 7;
    },
  },

}
</script>
 