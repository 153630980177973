import { GetUserInfoApi } from "@/request/api"

export default {
    namespaced:true,
    state:{
        //用户信息， 报存到本地
        userInfo:JSON.parse(localStorage.getItem("userInfo")) || []
    },
    mutations:{
        changeUserInfo(state,payload){
            state.userInfo = payload;
            localStorage.setItem("userInfo",JSON.stringify(state.userInfo))

        }
    },
    actions:{
        async asyncChangeUserInfo({commit},payload){
           //console.log("获取用户信息执行---------------")
           let GetUserInfoApiRes = await GetUserInfoApi()
           if(!GetUserInfoApiRes)return;
           commit("changeUserInfo",GetUserInfoApiRes.data)
        }
    }
}