
const STORAGE_KEY = {
    H5_TB_ANCHOR_INFO : 'ANCHORINOF', //淘宝主播提词页面的主播信息
    H5_CURRENT_PROMPT_INFO : 'current_prompt_item'
}; 


const getTbliveAnchorInfo = () => {
    return JSON.parse(localStorage.getItem(STORAGE_KEY['H5_TB_ANCHOR_INFO']));
}

const setTbliveAnchorInfo = (val) => {
    localStorage.setItem(STORAGE_KEY['H5_TB_ANCHOR_INFO'],JSON.stringify(val));
}

const removeTbliveAnchorInfo = (val) => {
    localStorage.removeItem(STORAGE_KEY['H5_TB_ANCHOR_INFO']);
} 

// const getTbH5CurrentPrompt = () => {
//     return JSON.stringify(localStorage.getItem(STORAGE_KEY['H5_CURRENT_PROMPT_INFO']));
// }

// const setTbH5CurrentPrompt

export {
    getTbliveAnchorInfo,
    setTbliveAnchorInfo,
    removeTbliveAnchorInfo
}




